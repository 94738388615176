// feature
import styled from 'styled-components'
import { breakpointDown, breakpointUp } from '@/utils/breakpoints'
import { mixins } from '@/utils/styleConfig'
import { Seo, LayoutContainer } from '@/utils/globalComponents'

// components
import Layout from '@/components/Layout'
import { HeadingLevel1 } from '@/components/HeadingLevel1'
import { HeadingLevel2 } from '@/components/HeadingLevel2'
import { LinkButton } from '@/components/LinkButton'
import { RoundedCornersContainer } from '@/components/RoundedCornersContainer'
import { BreakPointHiddenBr, TextThemeColor, VisuallyHidden } from '@/components/Utils'

const IndexPage = props => (
  <Layout>
    <Seo page="company" />
    <Company>
      <LayoutContainer>
        <HeadingLevel1 note="company">会社概要</HeadingLevel1>
        <Lead>
          <HeadingLevel2>
            お客様の『あったらいいな』にお応えし
            <BreakPointHiddenBr direction="down" breakpoint="sm" />
            <TextThemeColor>世の中の『楽』に貢献する</TextThemeColor>
          </HeadingLevel2>
          <Name>代表取締役社長　弥永 和久</Name>
        </Lead>
        <TextContainer>
          <Img>
            <RoundedCornersContainer>
              <img src="/images/company/img_photo01.jpg" alt="" width="630" height="490" />
            </RoundedCornersContainer>
          </Img>

          <p>
            創業132年目を迎える2020年に代表取締役社長に就任させていただきました。
            <br />
            それと同時に、新しく経営理念を「お客様の『あったらいいな』にお応えし、世の中の『楽』に貢献する」に一新し、会社名も株式会社木下製作所から
            <TextThemeColor>株式会社Ai-R（アイアール）</TextThemeColor>へ変更いたしました。
            名前の由来は、経営理念の精神を常に思い起こせるよう、経営理念自身を我々のアイデンティティとして名付けました。
          </p>
          <p>
            我々は、大きく二つの事業を営ませていただいております。一つ目の
            <TextThemeColor>情報機器事業</TextThemeColor>
            は、帳票に印刷・印字されたデータを活用する為のフォーム処理機器、郵送物に関わるメーリング機器・検査機器及び、複合技術を用いた情報システム機器の開発と製造を一貫して行っております。
            <br />
            この事業は、<TextThemeColor>お客様のお仕事をEasyにする『楽』を提供</TextThemeColor>
            させていただいております。
          </p>
          <p>
            もう一つの<TextThemeColor>ディスプレイ事業</TextThemeColor>
            は、科学館、博物館や商業施設など子供からお年寄りまで、見て・触って・感じていただくアイテムを数多く開発、設計から製作まで一貫して行っております。これらを通じ、余暇を過ごす安らぎや学び、つまり
            <TextThemeColor>Enjoyにする『楽』（楽しさ）を提供</TextThemeColor>
            させていただいております。
          </p>
          <p>
            また、これらの事業にとらわれず、お客様の「あったらいいな」に常にお応えする、世の中に必要とされる存在であり続けたいと社員一同邁進しております。
            <BreakPointHiddenBr direction="up" breakpoint="md" />
            新しく生まれ変わる株式会社Ai-Rを末永くよろしくお願い申し上げます。
          </p>
        </TextContainer>
      </LayoutContainer>
      <LayoutContainer bgColor="attention">
        <Information>
          <VisuallyHidden>
            <h2>会社情報</h2>
          </VisuallyHidden>
          <InformationDefinitionList>
            <InformationDefinitionListInner>
              <dt>名称</dt>
              <dd>株式会社 Ai-R（アイアール）</dd>
            </InformationDefinitionListInner>
            <InformationDefinitionListInner>
              <dt>本社</dt>
              <dd>
                <ul>
                  <li>〒604-8492</li>
                  <li>京都府京都市中京区西ノ京右馬寮町17-3</li>
                  <li>
                    TEL：<a href="tel:0758116194">075-811-6194（代）</a>
                  </li>
                  <li>FAX：075-801-3232</li>
                  <li>
                    <MapContainer>
                      <iframe
                        src="https://www.google.com/maps?&amp;q=日本、〒604-8492+京都府京都市中京区西ノ京右馬寮町１７−３&amp;z=18&amp;ll=35.0141386,135.7363836&amp;output=embed"
                        frameBorder="0"
                        style={{ border: 0 }}
                        allowFullScreen={true}
                        aria-hidden="false"
                        tabIndex={0}
                      ></iframe>
                    </MapContainer>
                  </li>
                  <li>
                    <p>JR「二条駅」／地下鉄東西線「二条」より 徒歩10分</p>
                    <p>京都市バス「太子道」より徒歩10分</p>
                    <p>阪急電車京都線「西院」より徒歩20分</p>
                  </li>
                </ul>
              </dd>
            </InformationDefinitionListInner>
            <InformationDefinitionListInner>
              <dt>東京営業所</dt>
              <dd>
                <ul>
                  <li>〒108-0073</li>
                  <li>東京都港区三田3-1-3 M・Kビル 7階</li>
                  <li>
                    TEL：<a href="tel:0368588830">03-6858-8830</a>
                  </li>
                  <li>FAX：03-6858-8832</li>
                  <li>
                    <MapContainer>
                      <iframe
                        src="https://www.google.com/maps?&amp;q=日本、〒108-0073+東京都港区三田３丁目１−３+7階&amp;z=18&amp;ll=35.6479174,139.742994&amp;output=embed"
                        frameBorder="0"
                        style={{ border: 0 }}
                        allowFullScreen={true}
                        aria-hidden="false"
                        tabIndex={0}
                      ></iframe>
                    </MapContainer>
                  </li>
                  <li>JR「田町駅」より徒歩10分 都営三田線「三田駅」より徒歩8分</li>
                </ul>
              </dd>
            </InformationDefinitionListInner>
            <InformationDefinitionListInner>
              <dt>創業</dt>
              <dd>1889（明治22）年2月11日</dd>
            </InformationDefinitionListInner>
            <InformationDefinitionListInner>
              <dt>設立</dt>
              <dd>1955（昭和30）年11月1日</dd>
            </InformationDefinitionListInner>
            <InformationDefinitionListInner>
              <dt>資本金</dt>
              <dd>1億円</dd>
            </InformationDefinitionListInner>
            <InformationDefinitionListInner>
              <dt>従業員数</dt>
              <dd>45名（2024年1月現在）</dd>
            </InformationDefinitionListInner>
            <InformationDefinitionListInner>
              <dt>売上高</dt>
              <dd>10億7900万円（2023年度実績）</dd>
            </InformationDefinitionListInner>
            <InformationDefinitionListInner>
              <dt>代表</dt>
              <dd>代表取締役社長　弥永 和久</dd>
            </InformationDefinitionListInner>
            <InformationDefinitionListInner>
              <dt>取引銀行</dt>
              <dd>京都信用金庫本店</dd>
            </InformationDefinitionListInner>
          </InformationDefinitionList>
        </Information>
      </LayoutContainer>
      <LayoutContainer>
        <History>
          <HistoryHeading>
            <HeadingLevel2 note="history">沿革</HeadingLevel2>
          </HistoryHeading>
          <HistoryDefinitionList>
            <HistoryDefinitionListInner>
              <dt>1889年2月</dt>
              <dd>
                木下製作所として機械設計、
                <br />
                木型製作および教育用理化学機器の製作を始める
              </dd>
            </HistoryDefinitionListInner>
            <HistoryDefinitionListInner>
              <dt>1946年4月</dt>
              <dd>マネキン人形部品および商品陳列器具の製作を始める</dd>
            </HistoryDefinitionListInner>
            <HistoryDefinitionListInner>
              <dt>1955年11月</dt>
              <dd>組織を「株式会社」に改める</dd>
            </HistoryDefinitionListInner>
            <HistoryDefinitionListInner>
              <dt>1965年9月</dt>
              <dd>トータル・ディスプレイの製作を始める</dd>
            </HistoryDefinitionListInner>
            <HistoryDefinitionListInner>
              <dt>1971年10月</dt>
              <dd>自社製品として、コンピューターのフォーム処理機を開発、販売する</dd>
            </HistoryDefinitionListInner>
            <HistoryDefinitionListInner>
              <dt>1987年4月</dt>
              <dd>メカトロニクス・ディスプレイの開発、製作を始める</dd>
            </HistoryDefinitionListInner>
            <HistoryDefinitionListInner>
              <dt>1989年2月</dt>
              <dd>創業100周年を迎える／資本金を１億円に増資する</dd>
            </HistoryDefinitionListInner>
            <HistoryDefinitionListInner>
              <dt>1989年4月</dt>
              <dd>本社新社屋を竣工する</dd>
            </HistoryDefinitionListInner>
            <HistoryDefinitionListInner>
              <dt>1989年6月</dt>
              <dd>京都府開庁記念日に「京の老舗」として、知事賞を受ける</dd>
            </HistoryDefinitionListInner>
            <HistoryDefinitionListInner>
              <dt>1989年12月</dt>
              <dd>京都府知事より（中小企業モデル工場）の指定を受ける</dd>
            </HistoryDefinitionListInner>
            <HistoryDefinitionListInner>
              <dt>2000年9月</dt>
              <dd>ISO9001認証を取得する</dd>
            </HistoryDefinitionListInner>
            <HistoryDefinitionListInner>
              <dt>2020年2月</dt>
              <dd>株式会社 Ai-R（アイアール）へと商号変更を行う</dd>
            </HistoryDefinitionListInner>
            <HistoryDefinitionListInner>
              <dt>2023年2月</dt>
              <dd>創業135周年を迎える</dd>
            </HistoryDefinitionListInner>
          </HistoryDefinitionList>
        </History>
        <LinkButtonContainer>
          <LinkButton label="お問い合わせ" link="/contact/" color="theme" />
        </LinkButtonContainer>
      </LayoutContainer>
    </Company>
  </Layout>
)

const Company = styled.div`
  position: relative;
`

const Name = styled.p`
  margin-top: 1.2rem;

  ${breakpointUp('md')} {
    margin-top: 2rem;
  }
`

const Lead = styled.section`
  margin-top: 8rem;

  ${breakpointUp('md')} {
    margin-top: 6rem;
  }
`

const Img = styled.div`
  ${breakpointDown('sm')} {
    margin-bottom: 2.4rem;
  }

  ${breakpointUp('md')} {
    float: left;
    margin-right: 3.6rem;
    margin-bottom: 1.6rem;
    width: ${mixins.vw({ size: 630 })};
  }

  ${breakpointUp('lg')} {
    width: 63rem;
  }
`

const TextContainer = styled.div`
  line-height: 1.8;
  letter-spacing: 0.04em;

  ${breakpointDown('sm')} {
    margin-top: 2.4rem;
    margin-bottom: 8rem;
    font-size: 1.6rem;
  }

  ${breakpointUp('md')} {
    margin-top: 4.8rem;
    margin-bottom: 10rem;
    font-size: 1.4rem;
  }

  > p + p {
    margin-top: 2.4rem;

    ${breakpointUp('md')} {
      margin-top: 2.8rem;
    }
  }
`

const Information = styled.section`
  padding-top: 8rem;
  padding-bottom: 8rem;

  ${breakpointUp('md')} {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
`

const InformationDefinitionList = styled.dl`
  ${breakpointUp('md')} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
`

const InformationDefinitionListInner = styled.div`
  ${breakpointUp('md')} {
    display: flex;
  }

  &:first-of-type {
    grid-column: 1 / span 2;
  }

  > dt {
    flex-basis: 10rem;
    flex-shrink: 0;
    font-weight: bold;

    ${breakpointDown('sm')} {
      font-size: 1.8rem;
    }
  }

  > dd {
  }

  & + & {
    margin-top: 4rem;
  }
`

const MapContainer = styled.div`
  margin: 1.6rem 0;
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 56.25%;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    height: 100%;
  }
`

const History = styled.section`
  padding-top: 8rem;

  ${breakpointUp('md')} {
    padding-top: 10rem;
  }
`

const HistoryHeading = styled.div``

const HistoryDefinitionList = styled.dl`
  margin-top: 4rem;
`

const HistoryDefinitionListInner = styled.div`
  ${breakpointUp('md')} {
    display: grid;
    grid-template-columns: 14rem 56rem;
    justify-content: flex-start;
  }

  > dt {
    font-weight: bold;

    ${breakpointDown('sm')} {
      font-size: 1.8rem;
    }
  }

  > dd {
  }

  & + & {
    margin-top: 4rem;

    ${breakpointUp('md')} {
      margin-top: 2.4rem;
    }
  }
`

const LinkButtonContainer = styled.div`
  text-align: center;
  margin-top: 4rem;
`

export default IndexPage
